// require('jquery')

// Bootstrap
// import "root/vendor/bootstrap/scss/bootstrap.scss";
// import "root/vendor/bootstrap/js/src/index.js";

import ProfilePageMaster from "../components/vue-components/ProfilePageMaster.vue";
import PercentageWheel from "../components/vue-components/PercentageWheel.vue";
import ProfileFeatured from "../components/vue-components/ProfileFeatured.vue";
import ProfileCategoriesMaster from "../components/vue-components/ProfileCategoriesMaster.vue"


import PersonalAchievementsBadge from "../components/vue-components/personal-achievements-badge/PersonalAchievementsBadge.vue";
import SurveyBadge from "../components/vue-components/SurveyBadge.vue";
import BadgeCarousel from "../components/vue-components/BadgeCarousel.vue";
import InfoIcon from "../components/vue-components/InfoIcon.vue";

import "./images/repeat2.gif"
import "./images/pch_icon.png"
import "./images/publishers-clearing-house.png"

// VIP
// import '@pch/vip/dist/css/all.css'
// import {bootstrap} from '@pch/vip'

// Components
import "compo/nav-bar/index.js";
import "compo/site-footer/index-desktop.js";
import "compo/modals/no-password/index-desktop.js";
// import 'compo/modals/earn-tokens/index-desktop.js'
// import 'compo/modals/optin/index.js'
// import 'compo/modals/tour-pch/index.js'
// import 'compo/modals/toast/index.js'

// Common JS Libraries
// import 'root/libs/async-carousel/style.scss'
// import AsyncCarousel from 'root/libs/async-carousel'
// AsyncCarousel()

// Self files
import "root/common/css/common-desktop.scss";
import "root/common/js/common-desktop.js";
// import Vue from "vue/dist/vue.esm-bundler.js";
import { createApp } from 'vue/dist/vue.esm-bundler.js'

// breadcrumb styles
//import 'compo/breadcrumb/breadcrumb.scss'

// import QueueClass from 'root/common/js/queue.js'

// const queue = new QueueClass()

// window.PCH = PCH || {}
// window.PCH.queue =  queue

// imgs
//require.context("root/common/images", true, /.*/);

// game over screens common css
// import '@pch/game-over-screen/dist/main.css'
//
// // site skin script
import "./js/site-skin.js";

// Instantiate Vip
// window.PCH.queue.add(() => {
// bootstrap()
// })
//
// bootstrap()
// PCH.queue.add( () => 'hi joe' )

//Quick mission bar code
window.addEventListener("DOMContentLoaded", () => {
  const missionBar = document.querySelector(".mission-bar");
  if (missionBar) {
    const uninav = document.querySelector(".uninav");
    uninav.classList.add("email-mission-top-bar");
  }

  const missionSteps = document.querySelectorAll(
    ".mission-bar .mission-bar__steps .mission-bar__step"
  );
  if (missionSteps && missionSteps.length > 1 && PCH.gameProxy) {
    PCH.gameProxy.addEventListener("gameEnd", () => {
      for (let missionStep of missionSteps) {
        if (!missionStep.classList.contains("mission-bar__completed-step")) {
          missionStep.classList.add("mission-bar__completed-step");
          break;
        }
      }
    });
  }
});

// Append Vue components

if(document.querySelector(".survey__profile-builder")){
  createApp({
  components: {
      "profile-page-master":
      ProfilePageMaster,
      "percentage-wheel":
      PercentageWheel,
      "profile-featured":
      ProfileFeatured,
      "profile-categories-master":
      ProfileCategoriesMaster,
    }
  }).mount(".survey__profile-builder")
}

if (document.querySelector("#personal-achievements-area")) {
  createApp({
    components:{
      "personal-achievements-badge":
        PersonalAchievementsBadge,
    }
  }).mount('#personal-achievements-area')
};

if(document.querySelector('.survey__profile-builder__badges')){
  createApp({
    components:{
      "survey-badge":
      SurveyBadge
    }
  }).mount(".survey__profile-builder__badges",)

}
if(document.querySelector('#special-badges')){
  createApp({    
    components:{
      "badge-carousel":
      BadgeCarousel
    }
  }).mount("#special-badges")

}

if(document.getElementById('achievment-header')){
  createApp({  
    components:{
      "info-icon":
      InfoIcon
    }
  }).mount('#achievment-header')
}

if(document.getElementById('special-header')){
  createApp({
    components:{
        "info-icon":
        InfoIcon
    }
  }).mount('#special-header')
}

if(document.getElementById('bonus-game-card')){
  createApp({
    components:{
        "info-icon":
        InfoIcon
    }
  }).mount('#bonus-game-card')
}

if(document.getElementById('benefits-table')){
  createApp({
    components:{
        "info-icon":
        InfoIcon
    }
  }).mount('#benefits-table')
}

if(document.getElementsByClassName('mission_info')){
  Array.from(document.getElementsByClassName('mission_info')).forEach((mission) => {
    createApp({  
      components:{
        "info-icon":
        InfoIcon
      }
    }).mount(mission)
  })
}