// import './overrides/uninav.js'
// import { socialGaTagging } from './pch-ga-tag/social-footer-ga-tagging'
// import '../../components/modals/treasure-chest/index.js'
import './pch-events/common-events.js';

import './legacy-bootstrap/carousel.js';
import './legacy-bootstrap/modal.js';
import './legacy-bootstrap/util.js';

import '../images/bbb-icon.png';
import '../images/iab.png';


// I think we can remove this

var PCH = window.PCH || {}

function waitForEvergage() {
    return new Promise((resolve, reject) => {
        const wait = 80;
        let retries = 100;

        let interval = setInterval(() => {
            if(window.Evergage) {
                clearInterval(interval);
                resolve();
            }

            if(retries === 0) {
                clearInterval(interval);
                reject('timed out');
            }

            retries--;
        }, wait);
    });
}

window.PCHEvents = window.PCHEvents || {};
window.PCHEvents.cmd = window.PCHEvents.cmd || [];

if(PCH?.evergageActionQueue) {
    PCH.evergageActionQueue.forEach(action => {
        window.PCHEvents.cmd.push(() => {
            waitForEvergage()
                .then(() => { window.Evergage.trackAction(action) })
                .catch(console.warn('Evergage not found in a timely manner'));
        })
    });
}



// function main() {
//     console.info('pch-pch-events', 'maybe running actions');
//     if(PCH?.evergageActionQueue) {
//         PCH.evergageActionQueue.map(window.PCHEvents.trackEvent.bind(window.PCHEvents, 'evergage'));
//         //window.PCHEvents.trackEvent('evergage', PCH?.evergageActionQueue);
//     }
// }

// waitForPchEvents()
//     .then(main)
//     .catch((e) => { console.warn('pch-pch-events: ', e) });

PCH.eventList = PCH.eventList || {}
PCH.eventList = Object.assign(PCH.eventList, {
  gameDropdownisActive: 'gamedropdownisactive',
  gameDropdownisInactive: 'gamedropdownisinactive'
})

// Earn tokens modal
// TK-338964 - move earn token to last item in uninav 12/30/2019

const eleMain = document.querySelector('.uninav__top-bar__links')

if (eleMain) {
  const ele = eleMain.querySelectorAll('a')
  const eleLength = ele.length
  for (let i = 0; i < eleLength; i++) {
    const pos = ele[i].text.indexOf('Earn') // 0

    if (pos !== -1) {
        ele[i].addEventListener('click', function (e) {
        e.preventDefault()
          $('#modal-earn-tokens').modal('show')
          return false
        })
        break;
    }
  }
}

// $('.uninav__top-bar__links .uninav__top-bar__link-item').last().on('click', (e) => {

// })

// social footer GA tag
//socialGaTagging()
