
document.addEventListener('DOMContentLoaded', () => {
    const bgContainer = document.getElementById('pch-site-skin');
    const pageName    = document.body.classList[1];
    console.log(PCH.siteSkin)

    

    if (pageName === 'survey-home' && PCH.siteSkin.length > 0){

        // left image
        let imgCenter = document.createElement('img');
        imgCenter.id = "skin-center";

        imgCenter.addEventListener('load', ()=>{
            if(PCH.siteSkin[0].href){
                let anchorCenter = document.createElement('a');
                anchorCenter.href = PCH.siteSkin[0].href;
                anchorCenter.target = "_blank";
                anchorCenter.appendChild(imgCenter);
                bgContainer.appendChild(anchorCenter);
            }else{
                bgContainer.append(imgCenter);
            }
            imgCenter.style.position = `absolute`;
            imgCenter.style.left = `50%`;
            imgCenter.style.transform = `translate(-50% , 0)`;

        });
        imgCenter.src = PCH.siteSkin[0].image
        
        
        if(PCH.siteSkin.length === 2){

            // left image
            let imgLeft = document.createElement('img');
            imgLeft.id = "skin-left";

            imgLeft.addEventListener('load', ()=>{
                if(PCH.siteSkin[0].href){
                    let anchorLeft = document.createElement('a');
                    anchorLeft.href = PCH.siteSkin[0].href;
                    anchorLeft.target = "_blank";
                    anchorLeft.appendChild(imgLeft);
                    bgContainer.appendChild(anchorLeft);
                }else{
                    bgContainer.append(imgLeft);
                }
                imgLeft.style.right = `calc(1165px/2 + 50vw)`;

            });
            imgLeft.src = PCH.siteSkin[0].image

            // right image

            let imgRight = document.createElement('img');
            imgRight.id = "skin-right";

            imgRight.addEventListener('load', ()=>{
                if(PCH.siteSkin[1].href){
                    let anchorRight = document.createElement('a');
                    anchorRight.href = PCH.siteSkin[1].href;
                    anchorRight.target = "_blank";
                    anchorRight.appendChild(imgRight)
                    bgContainer.appendChild(anchorRight);
                }else{
                    bgContainer.append(imgRight)
                }
                imgRight.style.left = `calc(1165px/2 + 50vw)`;
            });
            imgRight.src = PCH.siteSkin[1].image
            
        }
                
        bgContainer.style.display = 'block';
    }

    return false
})

// adjust vip bg position
const changeVipBgPos = (bgContainer) => {
    if( window.innerWidth <= 1780 ){
        bgContainer.style.left = `${ (window.innerWidth - 1780) / 2 }px`
    }else {
        bgContainer.style.left = 0
    }
};



